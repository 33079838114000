export const JobState = {
  INTERNAL_ADV: 'internal_adv',
  EXTERNAL_NOADV: 'external_noadv',
  EXTERNAL_ADV: 'external_adv',
  EXTERNAL_SEL: 'external_sel',
  INTERNAL_FILL: 'internal_fill',
  EXTERNAL_FILL: 'external_fill',
  CANCELED: 'canceled',
  HIDDEN: 'hidden',
}
