import React, { useEffect, useState } from "react";
import { object } from 'prop-types';
import styled from "styled-components";
import { connect } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import BigCalendarComponent from './BigCalendarComponent';
import RightCalendarPannel from './RightCalendarPannel';
import { Wrapper } from '../../globalOrganisationStyles';


const events = [
  {
    id: 1,
    title: 'Conference',
    start: new Date(2020, 0, 11),
    end: new Date(2020, 0, 13),
    desc: 'Big conference for important people',
    status: 'yellow'
  },
  {
    id: 2,
    title: 'Good news',
    start: new Date(2020, 0, 11),
    end: new Date(2020, 0, 17),
    desc: 'Big conference for important people',
    status: 'red'
  },
  {
    id: 6,
    title: 'lalalalalalalal',
    start: new Date(2020, 0, 9, 0, 0, 0),
    end: new Date(2020, 0, 16, 0, 0, 0),
    status: 'green'
  },
  {
    id: 7,
    title: 'sdsdse ekmfeom eiefeoeo',
    start: new Date(2020, 0, 9, 0, 0, 0),
    end: new Date(2020, 0, 10, 19, 30, 0),
    status: 'green'
  },
  {
    id: 8,
    title: 'Conference',
    start: new Date(2020, 0, 11),
    end: new Date(2020, 0, 13),
    desc: 'Big conference for important people',
    status: 'yellow'
  },
  {
    id: 9,
    title: 'Conference',
    start: new Date(2020, 0, 11),
    end: new Date(2020, 0, 13),
    desc: 'Big conference for important people',
    status: 'yellow'
  },
];

const RightPannelGrid = styled(Grid)`
  ${props => props.theme.breakpoints.down("md")} {
    margin-top: 80px !important;
    /* background: orange; */
    margin: 0 20px 0 20px;
  }
  background: transparent;
  border-radius: 5px;
  text-align: center;

`;

const LeftPannelGrid = styled(Grid)`
  /* margin: 0 20px 0 20px;*/
  /* background: orange;  */
  text-align: center;
  background: transparent;
`;

const propTypes = {
  local: object,
  match: object,
};


function OrganisationCalendar({
  local,
  match,
}) {

  const [selectedDate, setSelectedDate] = React.useState(null);
  const [currentEvents, setCurrentEvents] = useState([])

  useEffect(() => {
    let arr = events.filter(el => checkInRange(el.start, el.end, selectedDate));
    setCurrentEvents(arr)
  }, [selectedDate])

  function checkInRange(start, end, current) {
    return ((current >= start) && (current <= end))
}
  return (
      <Grid 
        container
        spacing={8}
        direction="row"
        justify="space-between"
        alignItems="stretch"
      >
        <LeftPannelGrid container item xs={12} lg={8}>
          <BigCalendarComponent
            local={local}
            setSelectedDate={setSelectedDate}
            events={events}
          />
        </LeftPannelGrid>
        <RightPannelGrid container item xs={12}  lg={4}>
          <RightCalendarPannel
            selectedDate={selectedDate}
            events={currentEvents}
          />
        </RightPannelGrid>
      </Grid>
  );
}

OrganisationCalendar.propTypes = propTypes;

const mapStateToProps = (state) =>({
  local: state.local,
});

export default connect(mapStateToProps)(OrganisationCalendar);