const prefix = 'adminPannel/';

export const GET_AGENCIES_LIST_REQUEST = `${prefix}GET_AGENCIES_LIST_REQUEST`;
export const GET_AGENCIES_LIST_SUCCESS = `${prefix}GET_AGENCIES_LIST_SUCCESS`;
export const GET_AGENCIES_LIST_FAIL = `${prefix}GET_AGENCIES_LIST_FAIL`;

export const DELETE_AGENCIES_REQUEST = `${prefix}DELETE_AGENCIES_REQUEST`;
export const DELETE_AGENCIES_SUCCESS = `${prefix}DELETE_AGENCIES_SUCCESS`;
export const DELETE_AGENCIES_FAIL = `${prefix}DELETE_AGENCIES_FAIL`;

export const GET_CURRENT_AGENCY_REQUEST = `${prefix}GET_CURRENT_AGENCY_REQUEST`;
export const GET_CURRENT_AGENCY_SUCCESS = `${prefix}GET_CURRENT_AGENCY_SUCCESS`;
export const GET_CURRENT_AGENCY_FAIL = `${prefix}GET_CURRENT_AGENCY_FAIL`;

export const CHANGE_CURRENT_AGENCY_REQUEST = `${prefix}CHANGE_CURRENT_AGENCY_REQUEST`;
export const CHANGE_CURRENT_AGENCY_SUCCESS = `${prefix}CHANGE_CURRENT_AGENCY_SUCCESS`;
export const CHANGE_CURRENT_AGENCY_FAIL = `${prefix}CHANGE_CURRENT_AGENCY_FAIL`;

export const ADD_NEW_AGENCY_REQUEST = `${prefix}ADD_NEW_AGENCY_REQUEST`;
export const ADD_NEW_AGENCY_SUCCESS = `${prefix}ADD_NEW_AGENCY_SUCCESS`;
export const ADD_NEW_AGENCY_FAIL = `${prefix}ADD_NEW_AGENCY_FAIL`;

export const GET_USERS_FOR_CURRENT_AGENCY_REQUEST = `${prefix}GET_USERS_FOR_CURRENT_AGENCY_REQUEST`;
export const GET_USERS_FOR_CURRENT_AGENCY_SUCCESS = `${prefix}GET_USERS_FOR_CURRENT_AGENCY_SUCCESS`;
export const GET_USERS_FOR_CURRENT_AGENCY_FAIL = `${prefix}GET_USERS_FOR_CURRENT_AGENCY_FAIL`;

export const DELETE_USERS_FOR_CURRENT_AGENCY_REQUEST = `${prefix}DELETE_USERS_FOR_CURRENT_AGENCY_REQUEST`;
export const DELETE_USERS_FOR_CURRENT_AGENCY_SUCCESS = `${prefix}DELETE_USERS_FOR_CURRENT_AGENCY_SUCCESS`;
export const DELETE_USERS_FOR_CURRENT_AGENCY_FAIL = `${prefix}DELETE_USERS_FOR_CURRENT_AGENCY_FAIL`;

export const GET_CURRENT_AGENCY_USER_REQUEST = `${prefix}GET_CURRENT_AGENCY_USER_REQUEST`;
export const GET_CURRENT_AGENCY_USER_SUCCESS = `${prefix}GET_CURRENT_AGENCY_USER_SUCCESS`;
export const GET_CURRENT_AGENCY_USER_FAIL = `${prefix}GET_CURRENT_AGENCY_USER_FAIL`;

export const CHANGE_CURRENT_AGENCY_USER_REQUEST = `${prefix}CHANGE_CURRENT_AGENCY_USER_REQUEST`;
export const CHANGE_CURRENT_AGENCY_USER_SUCCESS = `${prefix}CHANGE_CURRENT_AGENCY_USER_SUCCESS`;
export const CHANGE_CURRENT_AGENCY_USER_FAIL = `${prefix}CHANGE_CURRENT_AGENCY_USER_FAIL`;

export const INVITE_AGENCY_USER_REQUEST = `${prefix}INVITE_AGENCY_USER_REQUEST`;
export const INVITE_AGENCY_USER_SUCCESS = `${prefix}INVITE_AGENCY_USER_SUCCESS`;
export const INVITE_AGENCY_USER_FAIL = `${prefix}INVITE_AGENCY_USER_FAIL`;

export const GET_CUSTOMERS_FOR_CURRENT_AGENCY_REQUEST = `${prefix}GET_CUSTOMERS_FOR_CURRENT_AGENCY_REQUEST`;
export const GET_CUSTOMERS_FOR_CURRENT_AGENCY_SUCCESS = `${prefix}GET_CUSTOMERS_FOR_CURRENT_AGENCY_SUCCESS`;
export const GET_CUSTOMERS_FOR_CURRENT_AGENCY_FAIL = `${prefix}GET_CUSTOMERS_FOR_CURRENT_AGENCY_FAIL`;

export const DELETE_CUSTOMERS_FOR_CURRENT_AGENCY_REQUEST = `${prefix}DELETE_CUSTOMERS_FOR_CURRENT_AGENCY_REQUEST`;
export const DELETE_CUSTOMERS_FOR_CURRENT_AGENCY_SUCCESS = `${prefix}DELETE_CUSTOMERS_FOR_CURRENT_AGENCY_SUCCESS`;
export const DELETE_CUSTOMERS_FOR_CURRENT_AGENCY_FAIL = `${prefix}DELETE_CUSTOMERS_FOR_CURRENT_AGENCY_FAIL`;

export const INVITE_AGENCY_CUSTOMER_REQUEST = `${prefix}INVITE_AGENCY_CUSTOMER_REQUEST`;
export const INVITE_AGENCY_CUSTOMER_SUCCESS = `${prefix}INVITE_AGENCY_CUSTOMER_SUCCESS`;
export const INVITE_AGENCY_CUSTOMER_FAIL = `${prefix}INVITE_AGENCY_CUSTOMER_FAIL`;

export const GET_CURRENT_AGENCY_CUSTOMER_REQUEST = `${prefix}GET_CURRENT_AGENCY_CUSTOMER_REQUEST`;
export const GET_CURRENT_AGENCY_CUSTOMER_SUCCESS = `${prefix}GET_CURRENT_AGENCY_CUSTOMER_SUCCESS`;
export const GET_CURRENT_AGENCY_CUSTOMER_FAIL = `${prefix}GET_CURRENT_AGENCY_CUSTOMER_FAIL`;