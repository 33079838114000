import React, { Fragment, useState, useEffect } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import NursesJobUpload from './NursesJobUpload';
import MedicalsJobUpload from './MedicalsJobUpload';
import Tabs from '../../components/Tabs';

const propTypes = {
  local: object,
  match: object,
};

function JobUpload({ location }) {

  return (
    <Fragment>
      <Tabs
        items={[
          {
            name: 'Pflegekraft',
            component: (
              <NursesJobUpload/>
            )
          },
          {
            name: 'Anfrage',
            component: (
              <MedicalsJobUpload/>
            )
          },
        ]}
      />
    </Fragment>
  );
}

JobUpload.propTypes = propTypes;

const mapStateToProps = (state) =>({
  local: state.local,
});


export default withRouter(connect(mapStateToProps)(JobUpload));