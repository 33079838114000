import { blue, green, yellow, grey, indigo, red } from "@material-ui/core/colors";

const colors = {
  green400: '#90d286',
  green600: green[600],
  yellow900: '#fcb183',
  red300: red[300],
  blue700: blue[700],
  tableRowColor: "#f8f9fd"
};  


export default colors;
