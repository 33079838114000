import styled, { createGlobalStyle } from "styled-components";
import { NavLink } from "react-router-dom";


export const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }

  @font-face {
    font-family: 'Poppins';
    src: url('/static/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  #root>div {
    font-family: Poppins;
  }
`;

export const RouterNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${props => props.theme.palette.primary.main}
`;